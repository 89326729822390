import React, { Fragment } from 'react';
import { ResetCSS } from 'common/src/assets/css/style';
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import Footer from '../containers/Portfolio/Footer';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import Icon from 'react-icons-kit';
import { list } from 'react-icons-kit/feather/list';
import { GatsbyImage } from 'gatsby-plugin-image';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { portfolioTheme } from 'common/src/theme/portfolio';
import Helmet from 'react-helmet';
import {
  GlobalStyle,
  ContentWrapper
} from '../containers/Portfolio/portfolio.style';
import {
  PostsList,
  PostCard,
  PostCardBody,
  PostContentWrapper
} from '../containers/Blog/Main/main.style';
import Navbar from '../containers/Blog/Navbar';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const blogPosts = ({ data }) => {
  return (
    <ThemeProvider theme={portfolioTheme}>
      <Fragment>
        <Seo title="Almog Delopment Studio Blog & News | Bring ideas to life." />
        <Helmet>
          <meta
            property="og:image:secure"
            content={
              'https://www.almog.io' +
              data.allMarkdownRemark.edges[0].node.frontmatter.image.ImageUrl
            }
          />
          <meta
            property="og:image"
            content={
              'https://www.almog.io' +
              data.allMarkdownRemark.edges[0].node.frontmatter.image.ImageUrl
                .childImageSharp.gatsbyImageData.src
            }
          />
          <meta
            property="og:title"
            content="Almog Delopment Studio Blog & News | Bring ideas to life."
          />
          <meta property="og:url" content="https://www.almog.io/blog" />
          <meta
            property="og:description"
            content={data.site.siteMetadata.description}
          />
        </Helmet>
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          {' '}
          <Sticky
            top={0}
            innerZ={9999}
            activeClass="sticky-nav-active"
            className="sticky-nav-page"
          >
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <PostContentWrapper>
            <h1 style={{ fontSize: '35px !important', color: '#302B4E' }}>
              What’s cooking in the lab?
            </h1>
            <PostsList>
              {data.allMarkdownRemark.edges.map(({ node }) => {
                return (
                  <PostCard key={node.id} className="notes_list">
                    <h2>
                      <AniLink
                        paintDrip
                        hex="#56AADA"
                        to={node.frontmatter.slug}
                        className="headerLink"
                      >
                        {node.frontmatter.title}
                      </AniLink>
                    </h2>
                    <PostCardBody
                      style={{ fontFamily: 'Roboto', color: '#43414E' }}
                    >
                      <AniLink
                        paintDrip
                        hex="#56AADA"
                        to={node.frontmatter.slug}
                      >
                        <GatsbyImage
                          image={
                            node.frontmatter.image.ImageUrl
                              ? node.frontmatter.image.ImageUrl.childImageSharp
                                  .gatsbyImageData
                              : {}
                          }
                          imgStyle={{
                            width: '300px',
                            height: '200px',
                            borderRadius: '8px',
                            border: '2px solid #000',
                            objectFit: 'cover',
                          }}
                          style={{ overflow: 'visible', height: 200 }}
                          alt={node.frontmatter.image.ImageAlt}
                        />
                      </AniLink>
                      <div>
                        <p>
                          {node.excerpt}{' '}
                          <AniLink
                            paintDrip
                            hex="#56AADA"
                            to={node.frontmatter.slug}
                            className="post_link"
                          >
                            Continue reading{' '}
                            <span
                              style={{
                                fontStyle: 'italic',
                                fontSize: '14px',
                                color: '#aaa'
                              }}
                            >
                              ({node.timeToRead} min read)
                            </span>{' '}
                            →
                          </AniLink>
                        </p>

                        <span>
                          <span className="post_date">
                            {node.frontmatter.date}
                          </span>
                          <a
                            className="post_author"
                            href={
                              'https://twitter.com/' +
                              data.site.siteMetadata.author.slice(1)
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span style={{ padding: '5px' }}>&#8231;</span>
                            {node.timeToRead} min read{' '}
                            <span style={{ padding: '5px' }}>&#8231;</span>
                            {data.site.siteMetadata.author}
                          </a>
                        </span>
                        <span style={{ width: '80%', marginTop: '-18px' }}>
                          <span>
                            <Icon
                              icon={list}
                              style={{ color: '#43414E' }}
                              size={18}
                            />{' '}
                          </span>
                          <span className="post_tags">
                            {node.frontmatter.tags
                              ? node.frontmatter.tags.join(', ')
                              : ''}
                          </span>
                        </span>
                      </div>
                    </PostCardBody>
                  </PostCard>
                );
              })}
            </PostsList>
          </PostContentWrapper>
          <br></br>
          <br></br>
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export const query = graphql`
  {
    site {
      siteMetadata {
        author
        description
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: frontmatter___date }) {
      edges {
        node {
          excerpt(pruneLength: 500)
          id
          frontmatter {
            slug
            title
            date(formatString: "MMMM DD, YYYY")
            image {
              ImageAlt
              ImageUrl {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            tags
          }
          timeToRead
        }
      }
    }
  }
`;
export default blogPosts;
// backgroundColor: '#F9F9F9'
