import styled from 'styled-components';

export const PostsList = styled.div`
  display: grid;
  gap: 40px;
`;
export const PostCard = styled.div`
  display: grid;
  padding: 20px;
  gap: 3px;
  box-shadow: 5px 4px 17px #eee;
  .post_date {
    color: #43414e;
    font-size: 1rem;
    font-style: normal;
    text-transform: uppercase;
  }
  .post_author,
  .post_link,
  .post_tags {
    color: #43414e;
    font-size: 1rem;
  }
  h2,
  span,
  p {
    margin: 0;
    font-weight: 300;
    margin-bottom: 1rem;
    word-break: break-word;
  }
  span,
  p {
    font-size: 1.1rem;
  }
  a:not(.headerLink) {
    word-break: break-all;
  }
  h2 a {
    color: #302b4e;
    text-decoration: none;
    letter-spacing: 0;
    font-size: 1.5rem;
    @media (min-width: 768px) {
      font-size: 50px;
    }
  }
`;
export const PostCardBody = styled.div`
  display: grid;
  gap: 30px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 2fr;
  }
  @media (min-width: 992px) {
    grid-template-columns: 1fr 2fr;
  }
  @media (min-width: 1200px) {
    grid-template-columns: 1fr 3fr;
  }
`;
export const PostContentWrapper = styled.article`
  position: relative;
  min-height: 60vh;
  width: 90%;
  max-width: 1200px;
  margin: 170px auto 50px;
  display: grid;
  gap: 30px;
  h1 {
    margin: 30px 0 0;
    font-size: 2rem;
    font-weight: 700;
    @media (min-width: 768px) {
      font-size: 4rem;
    }
  }
  ul,
  ol,
  p {
    font-size: 1.2rem;
    line-height: 1.4;
  }
  li {
    list-style-type: disc;
    list-style: inside;
  }
  ul,
  ol {
    margin-left: 30px;
  }
  small {
    font-size: 1rem;
    line-height: 1.4;
  }
  header {
    margin-bottom: 2rem;
  }
  img {
    max-width: 100%;
  }
  .post_date {
    margin-top: 20px;
    color: #43414e;
    font-size: 15px;
    font-style: normal;
    text-transform: capitalize;
    line-height: 1.4;
  }
  .post_author,
  .post_link,
  .post_tags {
    color: #43414e;
    font-size: 15px;
    line-height: 1.4;
    margin-top: -18px;
    float: 'left';
  }
`;
